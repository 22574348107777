
.bradcam_area {
    background-size: cover;
    background-position: center center;
    padding: 271px 0 118px 0;
    background-repeat: no-repeat;
    position: relative;
    z-index: 0;
}

.bradcam_bg_2 {
    background-image: url(assets/images/img_1.jpg);
}

.bradcam_area h3 {
    font-size: 30px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: uppercase;
    margin-bottom: 14px;
}
.bradcam_area p {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 0;
}

.bradcam_area::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(left, rgba(0, 29, 56, 0.7) 0%, rgba(26, 13, 1, 0.6) 100%);
    background: -webkit-linear-gradient(left, rgba(0, 29, 56, 0.7) 0%, rgba(26, 13, 1, 0.6) 100%);
    background: linear-gradient(to right, rgba(0, 29, 56, 0.7) 0%, rgba(26, 13, 1, 0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3001d38', endColorstr='#991a0d01',GradientType=1 );
    z-index: -1;
    content: '';
}